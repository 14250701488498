import {Layout} from "../../components/Layout"
import {SectionContainer, SectionTitle} from "../../components/Shared"
import React, {useEffect, useState} from "react"
import {BADGES, BadgeType, RecalboxBadge} from "./Badges"
import {PaddedCard} from "../../components/GameShared"
import {Box, Chip, Grid, LinearProgress, TextField, Typography} from "@material-ui/core"
import styled from "@emotion/styled"
import * as queryString from "query-string"


const Plus = styled.span(({theme}) => ({
  fontWeight: "bold",
  color: theme.palette.secondary.main,
}))

const HighlightedChip = styled(Chip)(({theme}) => ({
  backgroundColor: theme.palette.background.highlight,
}))
const XPChip = (props: { className?: string, xp: number }) => {
  return (
    <HighlightedChip className={props.className} label={
      <Typography>
        <Plus>+</Plus> {props.xp} XP
      </Typography>
    } size={"small"}/>
  )
}

const BadgeTitle = styled(Typography)({
  fontWeight: "bold",
  textAlign: "center"
})
const BadgeDescription = styled(Typography)(({theme}) => ({
  textAlign: "center",
  fontSize: "1rem",
  color: theme.palette.text.secondary,
  height: "3rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
}))


export const BadgesPage = () => {
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    const searchQueryParam = queryString.parse(location.search)
    setSearch(searchQueryParam.search as string || "")
  }, [])

  const filterBadges = (badgeId: BadgeType) => {
    const badge = BADGES[badgeId]
    return (badge.description.indexOf(search) >= 0 || badge.name.indexOf(search) >= 0)
  }
  return (
    <Layout title={"Badges"}>
      <SectionContainer>
        <SectionTitle>
          Recalbox Badges
        </SectionTitle>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PaddedCard>
              <TextField
                label="Search"
                placeholder="Two players"
                variant="standard"
                name={"search"}
                InputLabelProps={{
                  shrink: true,
                }}
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
              />
            </PaddedCard>
          </Grid>
          {Object.keys(BADGES).map((e) => e as BadgeType).filter(filterBadges).map((badgeId) => {
            const badge = BADGES[badgeId]
            return (
              <Grid item xs={4}>
                <PaddedCard css={{position: "relative", overflow: "initial"}}>
                  <XPChip xp={badge.xp} css={{position: "absolute", right: "-1rem"}}/>
                  <Box textAlign={"center"}>
                    <RecalboxBadge badge={badgeId}></RecalboxBadge>
                  </Box>
                  <BadgeTitle>{badge.name}</BadgeTitle>
                  <BadgeDescription>{badge.description}</BadgeDescription>
                  <LinearProgress color={"secondary"} variant="determinate" value={Math.random() * 100}
                                  css={{margin: "1rem"}}/>
                </PaddedCard>
              </Grid>
            )
          })
          }
        </Grid>

      </SectionContainer>
    </Layout>
  )
}
